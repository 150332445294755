/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template v-slot:body>
            <ValidationObserver ref="carrOppForm">
              <form action="#">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field">
                      {{cvCarrFieldLabel}}</label>
                      <ValidationProvider
                      name="Career Field"
                      rules="required"
                      v-slot="{ errors }">
                        <multiselect
                          v-model="vmCareerOpportunityFormData.carr_field"
                          :options="cvCareerFieldOptions"
                          :placeholder="cvSelectBoxText + cvCarrFieldLabel"
                          label="carr_field"
                          track-by="carr_opp_id"
                          required>
                        </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_opp_title">
                      {{cvCarrOppTitleLabel}}</label>
                      <ValidationProvider
                      name="Career Opportunity"
                      rules="required"
                      v-slot="{ errors }">
                        <input
                          v-model="vmCareerOpportunityFormData.carr_opp_title"
                          type="text"
                          class="form-control"
                          required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_opp_desc">
                      {{cvCarrOppDescLabel}}</label>
                      <ValidationProvider
                      name="Career Opportunity Description"
                      rules="required"
                      v-slot="{ errors }">
                      <vue-editor v-model="vmCareerOpportunityFormData.carr_opp_desc"></vue-editor>
                      <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row spinner_add">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="careerOpportunityAdd()">
                    {{ cvSubmitBtn }}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Multiselect from "vue-multiselect"
import { CareerOpportunitys } from "../../../FackApi/api/careerOpportunity"
import ApiResponse from "../../../Utils/apiResponse.js"
import CareerUtility from "./CareerUtility"
import { VueEditor } from "vue2-editor"
import { ValidationProvider } from "vee-validate"

export default {
  name: "CareerOpportunityAdd",
  components: {
    Multiselect,
    VueEditor,
    ValidationProvider
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvSelectBoxText: "Select ",
      cvCardTitle: "Add careerOpportunity",
      cvSubmitBtn: "Add",
      cvCarrFieldLabel: "career",
      cvCarrImageLabel: "Add Image",
      cvCarrOppTitleLabel: "carr opportunity title",
      cvCarrOppDescLabel: "carr opportunity Description",
      cvCareerFieldOptions: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "CareerOpportunity",
      propStencilSize: null,
      vmCareerOpportunityFormData: Object.assign({}, this.initFormData()),
      vmCareerOpportunityDesc: null,
      vmCareerOpportunityType: null
    }
  },
  async mounted () {
    this.cvCareerFieldOptions = await this.loadRequiredData()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "carr_field": "",
        "carr_opp_title": "",
        "carr_opp_desc": ""
      }
    },
    loadRequiredData () {
      return CareerUtility.careerFieldList(this)
    },
    /**
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerOpportunityFormData) {
          if (!this.vmCareerOpportunityFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
    * careerOpportunityAdd
    */
    async careerOpportunityAdd () {
      let valid = await this.$refs.carrOppForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        this.vmCareerOpportunityFormData.carr_field = this.vmCareerOpportunityFormData.carr_field.carr_field
        let careerOpportunityAddResp = await CareerOpportunitys.careerOpportunityAdd(this, this.vmCareerOpportunityFormData)
        await ApiResponse.responseMessageDisplay(this, careerOpportunityAddResp)
        if (careerOpportunityAddResp && !careerOpportunityAddResp.resp_status) {
          return false
        }
        if (careerOpportunityAddResp.resp_data && careerOpportunityAddResp.resp_data.carr_opp_id) {
          this.cvLoadingStatus = false
          await this.imageAdd(careerOpportunityAddResp.resp_data.carr_opp_id)
          await this.$router.push("./careerPba_add")
        }
        this.vmCareerOpportunityFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at careerOpportunityAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
